// import { createSlice } from "@reduxjs/toolkit";
import { createAppSlice } from "../createAppSlice";

export interface initialType {
  token: string;
  isLoggedIn: boolean;
  profile: profile;
}
interface profile {
  _id: string;
  walletAddress: string;
  blockchain: string;
  createdAt: string;
}
const initialState: initialType = {
  token: "",
  isLoggedIn: false,
  profile: {
    _id: "",
    walletAddress: "",
    blockchain: "",
    createdAt: "",
  },
};

export const authSlice = createAppSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogout: (state) => {
      state.isLoggedIn = false;
      state.token = "";
    },
    setUser: (state, action) => {
      state.isLoggedIn = action.payload.auth;
      state.token = action.payload.token;
      state.profile = action.payload.profile;
    },
  },
});

export const { setLogout, setUser } = authSlice.actions;

export default authSlice.reducer;
