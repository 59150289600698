import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'

import { cookieStorage, createStorage } from 'wagmi'
import { mainnet, sepolia } from 'wagmi/chains'

// Get projectId at https://cloud.walletconnect.com
export const projectId = process.env.NEXT_PUBLIC_PROJECT_ID_WALLETCONNECT

if (!projectId) throw new Error('Project ID is not defined')

const metadata = {
    name: 'APTRON',
    description: 'APTRON',
    url: 'https://gptdroid.org', // origin must match your domain & subdomain
    icons: ["/aptron-logo.png"]
}

// Create wagmiConfig
const chains = [mainnet, sepolia]
export const config = defaultWagmiConfig({
    chains,
    projectId,
    metadata,
    ssr: true,
    storage: createStorage({
        storage: cookieStorage
    }),
    // ...wagmiOptions // Optional - Override createConfig parameters
})