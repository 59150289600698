import { baseURLDoc } from "@/api/api";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const fetchBaseQueryCb = fetchBaseQuery({
  baseUrl: baseURLDoc,
  prepareHeaders: (headers, { getState }: any) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseDocsApi = createApi({
  reducerPath: "baseDocsApi",
  baseQuery: fetchBaseQueryCb,

  tagTypes: ["doc"],

  endpoints: (builder) => ({
    uploadDocument: builder.mutation({
      query: (data) => ({
        url: "Upload",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["doc"],
    }),
    askQuestions: builder.mutation({
      query: (data) => ({
        url: "Search",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["doc"],
    }),
    getAllDocuments: builder.mutation({
      query: (data) => ({
        url: "Docs",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["doc"],
    }),
  }),
});

export const {
  useUploadDocumentMutation,
  useAskQuestionsMutation,
  useGetAllDocumentsMutation,
} = baseDocsApi;
